import React from 'react';

export const Address = (information) => {
    return(
        <>
            {information.address &&
                <span>
                    {information.address}
                </span>
            }
        </>
    )
}

export default Address;
