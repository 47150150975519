import React from "react";
import GlobalContent from "../../context/GlobalContext";

const Media = () => {

    const { dispatch, state } = React.useContext(GlobalContent);


    const handleChange = (e) => {
        const {name, value} = e.target;
        dispatch({
            type: "EDITINFO",
            payload: {
                name: name,
                value: value
            }
        })
    }

    return(
        <div>
            <h5>Media</h5>
            <div className="form-group">
                <label htmlFor="profilePicture" className={'form-control-label'}>Profile Picture</label>
                <input value={state["profilePicture"]} onChange={handleChange} type="text" className="form-control" id="profilePicture" name="profilePicture" placeholder="www.yourprofile.com" />
            </div>
            {/*<div className="form-group">*/}
            {/*    <label className={'es-input-label'}  htmlFor="organizationPicture">Organization Picture</label>*/}
            {/*    <input value={state["organizationPicture"]} onChange={handleChange} type="text" className="es-input" id="organizationPicture" name="organizationPicture" placeholder="www.yourorganization.com" />*/}
            {/*</div>*/}
        </div>
    )
}

export default Media;
