import React from "react";
import CommonItems from "../Common";
import GlobalContent from "../../context/GlobalContext";
import {
    copySignatureButtonCondition,
    templeteMiddleDesignRender
} from "../../helper/functions";
import TemplateContainer from "./TemplateContainer";
import {replicaState} from "../../helper/constant";

const Template2 = () => {

    let { state: {information} } = React.useContext(GlobalContent);

    information = copySignatureButtonCondition(information) ? information : replicaState;

    return(
            <TemplateContainer>
                <tr>
                    <td style={{ textAlign: "center" }}>
                        <CommonItems item={"ProfilePicture"}/>
                    </td>
                </tr>
                <tr>
                    <td height={10} />
                </tr>
                <tr style={{ textAlign: "center" }}>
                    <td>
                        <h3
                            color="#000000"
                            className="sc-fBuWsC eeihxG"
                            style={{ margin: "0px", fontSize: "18px", color: "rgb(0, 0, 0)" }}
                        >
                            <CommonItems item={"FullName"} />
                        </h3>
                        <p
                            color="#000000"
                            fontSize="medium"
                            className="sc-fMiknA bxZCMx"
                            style={{
                                margin: "0px",
                                color: "rgb(0, 0, 0)",
                                fontSize: "14px",
                                lineHeight: "22px",
                            }}
                        >
                            <CommonItems item={"JobTitle"} />
                        </p>
                        <p
                            color="#000000"
                            fontSize="medium"
                            className="sc-dVhcbM fghLuF"
                            style={{
                                margin: "0px",
                                fontWeight: 500,
                                color: "rgb(0, 0, 0)",
                                fontSize: "14px",
                                lineHeight: "22px",
                            }}
                        >
                            <CommonItems item={"Organization"} />
                        </p>
                        <p
                            color="#000000"
                            fontSize="medium"
                            className="sc-eqIVtm kRufpp"
                            style={{
                                color: "rgb(0, 0, 0)",
                                margin: "0px",
                                fontSize: "14px",
                                lineHeight: "22px",
                            }}
                        >
                            <CommonItems item={"Custominput"} />
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        {templeteMiddleDesignRender(information) && <CommonItems item={"BorderHorizontal"} />}
                        <table
                            cellPadding={0}
                            cellSpacing={0}
                            className="sc-gPEVay eQYmiW"
                            style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                                width: "100%",
                            }}
                        >
                            <tbody>
                            <tr style={{ verticalAlign: "middle" }}>
                                <td>
                                    <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        className="sc-gPEVay eQYmiW"
                                        style={{
                                            verticalAlign: "-webkit-baseline-middle",
                                            fontSize: "medium",
                                            fontFamily: "Arial",
                                        }}
                                    >
                                        <tbody>
                                        {(information.officePhone || information.personalPhone) && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"PhoneIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px", color: "rgb(0, 0, 0)"}}>
                                                <CommonItems item={"Phone"}/>
                                            </td>
                                        </tr>}
                                        {information.email && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"EmailIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px"}}>
                                                <CommonItems item={"Email"}/>
                                            </td>
                                        </tr>}
                                        {information.websiteUrl && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"WebsiteIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px"}}>
                                                <CommonItems item={"WebsiteUrl"}/>
                                            </td>
                                        </tr>}
                                        {information.address && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"AddressIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px"}}>
                                                        <span
                                                            color="#000000"
                                                            className="sc-csuQGl CQhxV"
                                                            style={{fontSize: "12px", color: "rgb(0, 0, 0)"}}
                                                        >
                                                            <CommonItems item={"Address"}/>
                                                        </span>
                                            </td>
                                        </tr>}
                                        </tbody>
                                    </table>
                                </td>
                                <>
                                {/*<td width={15}>*/}
                                {/*    <div />*/}
                                {/*</td>*/}
                                {/*<td style={{ textAlign: "right" }}>*/}
                                {/*    <table*/}
                                {/*        cellPadding={0}*/}
                                {/*        cellSpacing={0}*/}
                                {/*        className="sc-gPEVay eQYmiW"*/}
                                {/*        style={{*/}
                                {/*            verticalAlign: "-webkit-baseline-middle",*/}
                                {/*            fontSize: "medium",*/}
                                {/*            fontFamily: "Arial",*/}
                                {/*            width: "100%",*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>*/}

                                {/*                <CommonItems item={"OrganizationPicture"} />*/}
                                {/*            </td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td height={10} />*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>*/}
                                {/*                <table*/}
                                {/*                    cellPadding={0}*/}
                                {/*                    cellSpacing={0}*/}
                                {/*                    className="sc-gPEVay eQYmiW"*/}
                                {/*                    style={{*/}
                                {/*                        verticalAlign: "-webkit-baseline-middle",*/}
                                {/*                        fontSize: "medium",*/}
                                {/*                        fontFamily: "Arial",*/}
                                {/*                        display: "inline-block",*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <tbody>*/}
                                {/*                    <tr style={{ textAlign: "right" }}>*/}
                                {/*                        <td>*/}
                                {/*                            <CommonItems item={"Facebook"} />*/}
                                {/*                        </td>*/}
                                {/*                        <td width={5}>*/}
                                {/*                            <div />*/}
                                {/*                        </td>*/}
                                {/*                        <td>*/}
                                {/*                            <CommonItems item={"Twitter"} />*/}
                                {/*                        </td>*/}
                                {/*                        <td width={5}>*/}
                                {/*                            <div />*/}
                                {/*                        </td>*/}
                                {/*                        <td>*/}
                                {/*                            <CommonItems item={"Instagram"} />*/}
                                {/*                        </td>*/}
                                {/*                        <td width={5}>*/}
                                {/*                            <div />*/}
                                {/*                        </td>*/}
                                {/*                        <td>*/}
                                {/*                            <CommonItems item={"Linkedin"} />*/}
                                {/*                        </td>*/}
                                {/*                        <td width={5}>*/}
                                {/*                            <div />*/}
                                {/*                        </td>*/}
                                {/*                    </tr>*/}
                                {/*                    </tbody>*/}
                                {/*                </table>*/}
                                {/*            </td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}
                                {/*</td>*/}
                                </>
                            </tr>
                            </tbody>
                        </table>
                        {templeteMiddleDesignRender(information) && <CommonItems item={"BorderHorizontal"} />}
                    </td>
                </tr>
            </TemplateContainer>
    )
}

export default Template2
