import React from "react";

export const signatureDetails = [
    {
        type: "text",
        name: "fullName",
        label: "Full Name",
        placeholder: "Parvez Akhter",
    },
    {
        type: "text",
        name: "jobTitle",
        label: "Job Title",
        placeholder: "CEO"
    },
    {
        type: "text",
        name: "companyName",
        label: "Company Name",
        placeholder: "ThriveDesk"
    },
    {
        phone: true,
        items: [
            {
                type: "tel",
                name: "personalPhone",
                label: "Personal Phone Number",
                placeholder: "Personal"
            },
            {
                type: "tel",
                name: "officePhone",
                label: "Office Phone Number",
                placeholder: "Official"
            },
        ]
    },
    {
        type: "url",
        name: "websiteUrl",
        label: "Website URL",
        placeholder: "www.thrivedesk.io"
    },
    {
        type: "email",
        name: "email",
        label: "Email Address",
        placeholder: "hello@example.com"
    },
    {
        type: "text",
        name: "address",
        label: "Address",
        placeholder: "193/2, San Francisco"
    },
]

// export const socialLinks = [
//     {
//         type: "text",
//         name: "linkedin",
//         label: "Linkedin",
//         placeholder: "www.linkedin.com",
//     },
//     {
//         type: "text",
//         name: "facebook",
//         label: "Facebook",
//         placeholder: "www.facebook.com",
//     },
//     {
//         type: "text",
//         name: "twitter",
//         label: "Twitter",
//         placeholder: "www.twitter.com",
//     },
//     {
//         type: "text",
//         name: "instagram",
//         label: "Instagram",
//         placeholder: "www.instagram.com",
//     }
// ]

export const replicaState ={
    websiteUrl: "www.thrivedesk.io",
    // twitter: "https://www.themexpert.com/",
    // linkedin: "https://www.themexpert.com/",
    // facebook: "https://www.themexpert.com/",
    // instagram: "https://www.themexpert.com/",
    customInput: "On demand solution provider",
    address: "192/2, San francisco",
    companyName: "ThriveDesk",
    // department: "Technical",
    email: "hello@example.com",
    fullName: "Parvez Akther",
    jobTitle: "CEO",
    officePhone: "111 222 333",
    personalPhone: "111 222 333",
    profilePicture: "https://avatars1.githubusercontent.com/u/65561215?s=200&v=4",
    // organizationPicture: "https://s3-eu-west-1.amazonaws.com/tpd/logos/5cf7e09057207800016f09d3/0x0.png",
}

export const tabOptions = [
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none"><path d="M22 12.634c-4 3.512-4.572-2.013-6.65-1.617-2.35.447-3.85 5.428-2.35 5.428s-.5-5.945-2.5-3.89c-2 2.056-2.64 4.74-4.265 2.748C-1.5 5.813 5-1.15 8.163 3.457 10.165 6.373 6.5 16.977 2 22M9 21h10" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/></svg>,
        name: "contents",
        label: "Details"
    },
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75c.052 0 .11 0 .175.002.36.005.899.013 1.343-.111.288-.08.614-.233.864-.534.258-.309.368-.693.368-1.107 0-.841-.432-1.48-.727-1.917l-.032-.048c-.244-.361-.394-.597-.46-.844-.052-.2-.06-.456.14-.856.24-.48.518-.673.89-.781.45-.13 1.032-.137 1.92-.137h.012c.85 0 1.894 0 3.113-.175 1.205-.172 2.063-.657 2.574-1.5.475-.787.57-1.767.57-2.742 0-5.937-4.813-10.75-10.75-10.75ZM6.738 14.037a1 1 0 0 0 .515 1.931l.009-.002a1 1 0 0 0-.515-1.932l-.009.003ZM7.25 8.5a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm9.25-1.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" fill="currentColor"/></svg>,
        name: "styles",
        label: "Design"
    },
    {
        svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.99 1.5c-.465.002-.698.002-.844.148-.146.147-.146.38-.146.848V10c0 .472 0 .707.146.854.147.146.383.146.854.146h10.497c.474 0 .71 0 .857-.148.147-.148.145-.383.14-.854-.012-1.304-.052-2.412-.177-3.343-.189-1.405-.589-2.558-1.501-3.47-.912-.913-2.066-1.313-3.47-1.502-1.36-.183-3.096-.183-5.272-.183h-1.083Zm11.504 12.502c.005-.47.007-.706-.14-.854-.146-.148-.383-.148-.857-.148H11c-.471 0-.707 0-.854.147C10 13.293 10 13.529 10 14v7.504c0 .468 0 .701.146.848.146.146.379.147.845.148h1.084c2.175 0 3.91 0 5.27-.183 1.405-.189 2.559-.589 3.47-1.501.913-.912 1.313-2.066 1.502-3.47.125-.932.165-2.04.177-3.344ZM1.5 11.927v.149c0 2.176 0 3.91.183 5.27.189 1.405.589 2.559 1.501 3.471.913.913 2.066 1.313 3.47 1.501.096.013.193.025.292.036.48.054.72.08.887-.068.167-.15.167-.4.167-.904V2.62c0-.503 0-.754-.167-.903-.166-.15-.406-.123-.887-.069-.099.011-.196.023-.291.036-1.405.189-2.558.589-3.47 1.501-.913.913-1.313 2.067-1.502 3.47C1.5 8.017 1.5 9.752 1.5 11.928Z" fill="currentColor"/></svg>,
        name: "templates",
        label: "Templates"
    },
]