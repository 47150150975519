import React from 'react';

const TemplateContainer = ({children}) => {
    return(
        <table
            id={"es-landscape_template"}
            cellPadding={0}
            cellSpacing={0}
            className="sc-gPEVay eQYmiW"
            style={{
                verticalAlign: "-webkit-baseline-middle",
                fontSize: "medium",
                fontFamily: "Arial",
            }}
        >
            <tbody>
                {children}
            </tbody>
        </table>
    )
}

export default TemplateContainer
