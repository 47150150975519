import React from 'react';

export const JobTitle = (information) => {
    return(
        <>
            {information.jobTitle &&
                <span style={{fontSize: "13px"}}>
                    {information.jobTitle}
                </span>
            }
        </>
    )
}
