import React from 'react';

export const ProfilePicture = (information) => {
    return(
        <>
            {information.profilePicture &&
                    <img style={{ maxWidth: "90px" }} src={`${information.profilePicture ? information.profilePicture : './media/images/profilepic.png'}`} alt="profile-img"/>
            }
        </>

    )
}
