export const reducer = (state, action) => {
    switch (action.type) {
        case "EDITINFO":
            let editedState = {
                ...state.information,
                [action.payload.name]: action.payload.value
            }
            let newState = {
                ...state,
                information: editedState
            }
            return newState
        case "EDIT":
            return({
                ...state,
                [action.payload.name]: action.payload.value
            })
        case "CLEAR":
            let resetState = {
                primary: state.primary,
                secondary: state.secondary,
                currentTemplate: state.currentTemplate,
            }
            return resetState
        default:
            return state;
    }
};
