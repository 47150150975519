import React from "react";
import CommonItems from "../Common";
import GlobalContent from "../../context/GlobalContext";
import {
    copySignatureButtonCondition,
    templeteBottomDesignRender,
    templeteMiddleDesignRender
} from "../../helper/functions";
import TemplateContainer from "./TemplateContainer";
import {replicaState} from "../../helper/constant";

const Template3 = () => {

    let { state: {information} } = React.useContext(GlobalContent);

    information = copySignatureButtonCondition(information) ? information : replicaState;

    return(
            <TemplateContainer>
                <tr>
                    <td>
                        <table
                            cellPadding={0}
                            cellSpacing={0}
                            className="sc-gPEVay eQYmiW"
                            style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial"
                            }}
                        >
                            <tbody>
                            <tr>
                                <td width={105} style={{verticalAlign: "middle"}}>
                                        <span className="sc-kgAjT cuzzPp"
                                              style={{marginRight: "12px", display: "block"}}>
                                          <CommonItems item={"ProfilePicture"}/>
                                        </span>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    {information.fullName && <h3
                                        color="#000000"
                                        className="sc-fBuWsC eeihxG"
                                        style={{
                                            margin: "0px",
                                            fontSize: "18px",
                                            color: "rgb(0, 0, 0)",
                                        }}
                                    >
                                        <CommonItems item={"FullName"}/>
                                    </h3>}
                                    {information.jobTitle && <div
                                        color="#000000"
                                        fontSize="medium"
                                        className="sc-fMiknA bxZCMx"
                                        style={{
                                            margin: "0px",
                                            color: "rgb(0, 0, 0)",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                        }}
                                    >
                                        <CommonItems item={"JobTitle"}/>
                                    </div>}
                                    {information.companyName && <div
                                        color="#000000"
                                        fontSize="medium"
                                        className="sc-dVhcbM fghLuF"
                                        style={{
                                            margin: "0px",
                                            fontWeight: 500,
                                            color: "rgb(0, 0, 0)",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                        }}
                                    >
                                        <CommonItems item={"Organization"}/>
                                    </div>}
                                    {information.customInput && <div
                                        color="#000000"
                                        fontSize="medium"
                                        className="sc-eqIVtm kRufpp"
                                        style={{
                                            color: "rgb(0, 0, 0)",
                                            margin: "0px",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                        }}
                                    >
                                        <CommonItems item={"Custominput"}/>
                                    </div>}
                                </td>
                                {templeteMiddleDesignRender(information) && <CommonItems item={"BorderVertical"} />}
                                <td style={{ verticalAlign: "middle" }}>
                                    <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        className="sc-gPEVay eQYmiW"
                                        style={{
                                            verticalAlign: "-webkit-baseline-middle",
                                            fontSize: "medium",
                                            fontFamily: "Arial",
                                        }}
                                    >
                                        <tbody>
                                        {(information.officePhone || information.personalPhone) && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"PhoneIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px", color: "rgb(0, 0, 0)"}}>
                                                <CommonItems item={"Phone"}/>
                                            </td>
                                        </tr>}
                                        {information.email && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"EmailIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px"}}>
                                                <CommonItems item={"Email"}/>
                                            </td>
                                        </tr>}
                                        {information.websiteUrl && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"WebsiteIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px"}}>
                                                <CommonItems item={"WebsiteUrl"}/>
                                            </td>
                                        </tr>}
                                        {information.address && <tr height={25} style={{verticalAlign: "middle"}}>
                                            <td width={20} style={{verticalAlign: "middle"}}>
                                                <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    className="sc-gPEVay eQYmiW"
                                                    style={{
                                                        verticalAlign: "-webkit-baseline-middle",
                                                        fontSize: "medium",
                                                        fontFamily: "Arial",
                                                    }}
                                                >
                                                    <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: "bottom"}}>
                                                            <CommonItems item={"AddressIcon"}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style={{padding: "0px"}}>
                                                    <span
                                                        color="#000000"
                                                        className="sc-csuQGl CQhxV"
                                                        style={{fontSize: "12px", color: "rgb(0, 0, 0)"}}
                                                    >
                                                        <CommonItems item={"Address"}/>
                                                    </span>
                                            </td>
                                        </tr>}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        {templeteBottomDesignRender(information) && <CommonItems item={"BorderVertical"} />}
                    </td>
                </tr>
            </TemplateContainer>
    )
}

export default Template3
