import React, {useReducer} from "react";
import {reducer} from "./context/Reducers";
import {initialState} from "./context/InitialState";
import GlobalContent from './context/GlobalContext';
import MainContainer from "./MainContainer";

function App() {

    const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <div className="App">
        <GlobalContent.Provider value={{state, dispatch}}>
                <MainContainer />
        </GlobalContent.Provider>
    </div>
  );
}

export default App;
