import React from 'react';
import CommonItems from "../index";
import {templeteMiddleDesignRender} from "../../../helper/functions";

export const TemplateCommonContent = (information) => {
    return(
        <td style={{ verticalAlign: "top" }}>
            <h3
                color="#000000"
                className="sc-fBuWsC eeihxG"
                style={{
                    margin: "0px",
                    fontSize: "18px",
                    color: "rgb(0, 0, 0)",
                }}
            >
                <CommonItems item={"FullName"} />
            </h3>
            <p
                color="#000000"
                fontSize="medium"
                className="sc-fMiknA bxZCMx"
                style={{
                    margin: "0px",
                    color: "rgb(0, 0, 0)",
                    fontSize: "14px",
                    lineHeight: "22px",
                }}
            >
                <CommonItems item={"JobTitle"} />
            </p>
            <p
                color="#000000"
                fontSize="medium"
                className="sc-dVhcbM fghLuF"
                style={{
                    margin: "0px",
                    fontWeight: 500,
                    color: "rgb(0, 0, 0)",
                    fontSize: "14px",
                    lineHeight: "22px",
                }}
            >
                <CommonItems item={"Organization"} />
            </p>
            <p
                color="#000000"
                fontSize="medium"
                className="sc-eqIVtm kRufpp"
                style={{
                    color: "rgb(0, 0, 0)",
                    margin: "0px",
                    fontSize: "14px",
                    lineHeight: "22px",
                }}
            >
                <CommonItems item={"Custominput"} />
            </p>

            {templeteMiddleDesignRender(information) && <CommonItems item={"BorderHorizontal"} />}
            <table
                cellPadding={0}
                cellSpacing={0}
                className="sc-gPEVay eQYmiW"
                style={{
                    verticalAlign: "-webkit-baseline-middle",
                    fontSize: "medium",
                    fontFamily: "Arial",
                }}
            >
                <tbody>
                {(information.officePhone || information.personalPhone) && <tr height={25} style={{verticalAlign: "middle"}}>
                    <td width={20} style={{verticalAlign: "middle"}}>
                        <CommonItems item={"PhoneIcon"}/>
                    </td>
                    <td style={{padding: "0px", color: "rgb(0, 0, 0)"}}>
                        <CommonItems item={"Phone"}/>
                    </td>
                </tr>}
                {information.email && <tr height={25} style={{verticalAlign: "middle"}}>
                    <td width={20} style={{verticalAlign: "middle"}}>
                        <CommonItems item={"EmailIcon"}/>
                    </td>
                    <td style={{padding: "0px"}}>
                        <CommonItems item={"Email"}/>
                    </td>
                </tr>}
                {information.websiteUrl && <tr height={25} style={{verticalAlign: "middle"}}>
                    <td width={20} style={{verticalAlign: "middle"}}>
                        <CommonItems item={"WebsiteIcon"}/>
                    </td>
                    <td style={{padding: "0px"}}>
                        <CommonItems item={"WebsiteUrl"}/>
                    </td>
                </tr>}
                {information.address && <tr height={25} style={{verticalAlign: "middle"}}>
                    <td width={20} style={{verticalAlign: "middle"}}>
                        <CommonItems item={"AddressIcon"}/>
                    </td>
                    <td style={{padding: "0px"}}>
                        <span
                            color="#000000"
                            className="sc-csuQGl CQhxV"
                            style={{fontSize: "12px", color: "rgb(0, 0, 0)"}}
                        >
                          <CommonItems item={"Address"}/>
                        </span>
                    </td>
                </tr>}
                </tbody>
            </table>

        </td>
    )
}
