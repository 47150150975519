import React from 'react';

export const BorderHorizontal = (information, state) => {
    return(
        <>
            <table cellPadding={0} cellSpacing={0} className="sc-gPEVay eQYmiW" style={{
                verticalAlign: "-webkit-baseline-middle",
                fontSize: "medium",
                fontFamily: "Arial",
                width: "100%",
            }}>
                <tbody>
                    <tr>
                        <td height={10} />
                    </tr>
                    <tr>
                        <td
                            color="#F2547D"
                            direction="horizontal"
                            height={1}
                            className="sc-jhAzac hmXDXQ"
                            style={{
                                width: "100%",
                                borderBottom: `1px solid ${state.primary}`,
                                borderLeft: "none",
                                display: "block",
                            }}
                        />
                    </tr>
                    <tr>
                        <td height={10} />
                    </tr>
                </tbody>
            </table>
        </>
    )
}
