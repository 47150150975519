import React from 'react';

export const Instagram = (information, state) => {
    return(
        <>
            {information.instagram &&
                <a
                    href={information.instagram}
                    color="#6A78D1"
                    className="sc-hzDkRC kpsoyz"
                    style={{
                        display: "inline-block",
                        padding: "0px",
                        backgroundColor: state.secondary,
                    }}
                >
                    <img
                        src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/instagram-icon-2x.png"
                        alt="Twitter"
                        color="#6A78D1"
                        height={24}
                        className="sc-bRBYWo ccSRck"
                        style={{
                            backgroundColor: state.secondary,
                            maxWidth: "135px",
                            display: "block",
                        }}
                    />
                </a>
            }
        </>
    )
}
