import React from "react";
import GlobalContent from "../../context/GlobalContext";

const Overlay = () => {
    return(
        <div className="absolute inset-x-0 inset-y-0 bg-blue-500/15">
            <div className="flex items-center justify-center w-full h-full">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#2563eb" fill="none"><path d="M17 3.338A9.954 9.954 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-.685-.069-1.354-.2-2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M8 12.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
        </div>
    )
}

const Wireframes = () => {

    const { state, dispatch } = React.useContext(GlobalContent);

    const changeTemplete = (tem) => {
        dispatch({
            type: "EDIT",
            payload: {
                name: "currentTemplate",
                value: tem
            }
        })
    }

    return(
        <>
            <div className="font-semibold mb-6 pb-3 border-b">Select from our tempalte</div>

            <div className="grid grid-cols-2 gap-2 text-center text-sm text-gray-500">                
                <div className="border p-1 rounded cursor-pointer relative" onClick={() => {changeTemplete("1")}}>
                    {state.currentTemplate === "1" && <Overlay/>}
                    <img src={"./media/images/template-1.svg"} alt=""/>
                </div>
                <div className="border p-1 rounded cursor-pointer relative" onClick={() => {changeTemplete("2")}}>
                    {state.currentTemplate === "2" && <Overlay/>}
                    <img src={"./media/images/template-2.svg"} alt=""/>
                </div>
                <div className="border p-1 rounded cursor-pointer relative" onClick={() => {changeTemplete("3")}}>
                    {state.currentTemplate === "3" && <Overlay/>}
                    <img  src={"./media/images/template-3.svg"} alt=""/>
                </div>
                <div className="border p-1 rounded cursor-pointer relative" onClick={() => {changeTemplete("4")}}>
                    {state.currentTemplate === "4" && <Overlay/>}
                    <img  src={"./media/images/template-4.svg"} alt=""/>
                </div>
            </div>
        </>
    )
}

export default Wireframes;
