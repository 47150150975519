import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';

ReactDOM.render(
  <React.StrictMode>
      <PerfectScrollbar>
          <App />
      </PerfectScrollbar>
  </React.StrictMode>,
  document.getElementById('root')
);
