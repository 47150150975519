import React from 'react';

export const OrganizationPicture = (information) => {
    return(
        <>
            {information.organizationPicture &&
                <img
                    width={105}
                    style={{ maxWidth: "130px", display: "inline-block" }}
                    src={information.organizationPicture}
                    alt="organization-img" />
            }
        </>

    )
}
