import React from "react";
import CommonItems from "../Common";
import TemplateContainer from "./TemplateContainer";

const Template4 = () => {

    return(
            <TemplateContainer>
                <tr>
                    <td>
                        <table
                            cellPadding={0}
                            cellSpacing={0}
                            className="sc-gPEVay eQYmiW"
                            style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                            }}
                        >
                            <tbody>
                            <tr>
                                <CommonItems item={"TemplateCommonContent"} />
                                <td width={15}>
                                    <div />
                                </td>
                                <CommonItems item={"TemplateCommonMedia"} />
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </TemplateContainer>
    )
}

export default Template4
