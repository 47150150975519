import {Phone} from "./Phone";
import {Email} from "./Email";
import {Address} from "./Address";
import {Twitter} from "./Twitter";
import {FullName} from "./FullName";
import {Facebook} from "./Facebook";
import {JobTitle} from "./JobTitle";
import {Linkedin} from "./Linkedin";
import {EmailIcon} from "./EmailIcon";
import {Instagram} from "./Instagram";
import {PhoneIcon} from "./PhoneIcon";
import {WebsiteUrl} from "./WebsiteUrl";
import {Custominput} from "./Custominput";
import {AddressIcon} from "./AddressIcon";
import {WebsiteIcon} from "./WebsiteIcon";
import {Organization} from "./Organization";
import {ProfilePicture} from "./ProfilePicture";
import {BorderVertical} from "./BorderVertical";
import {BorderHorizontal} from "./BorderHorizontal";
import {OrganizationPicture} from "./OrganizationPicture";
import {TemplateCommonMedia} from "./TemplateCommonMedia";
import {TemplateCommonContent} from "./TemplateCommonContent";

let Fields = {
    Phone,
    Email,
    Address,
    Twitter,
    FullName,
    Facebook,
    JobTitle,
    Linkedin,
    EmailIcon,
    Instagram,
    PhoneIcon,
    WebsiteUrl,
    AddressIcon,
    Custominput,
    WebsiteIcon,
    Organization,
    ProfilePicture,
    BorderVertical,
    BorderHorizontal,
    OrganizationPicture,
    TemplateCommonMedia,
    TemplateCommonContent,
};

export default Fields;
