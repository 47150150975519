import React, {useEffect, useState} from "react";
import { ChromePicker } from 'react-color';
import GlobalContent from "../../context/GlobalContext";

const Customizations = () => {

    const { state, state: {information}, dispatch } = React.useContext(GlobalContent);

    const [localState, setState] = useState({
        option: null
    })

    const {option} = localState;

    const handleOptions = (val) => {
        if(option === val) {
            setState(prevState => ({...prevState, option: null}))
        } else {
            setState(prevState => ({...prevState, option: val}))
        }
    }

    useEffect(() => {
        let chromePicker = document.querySelector(".chrome-picker")
        let colorPicker = [...document.querySelectorAll(".es-color-tab")]
        window.addEventListener("click", e => {
            const {target} = e;
            if(chromePicker){
                if(!chromePicker.contains(target) && !colorPicker.includes(target)){
                    setState(prevState => ({...prevState, option: null}))
                    chromePicker = null
                }
            }
        })
    }, [option])

    const handleChangeComplete = (color, type, name) => {
        dispatch({
            type: type,
            payload: {
                name: name,
                value: name === "profilePicture" ? color.target.value : color.hex
            }
        })
    };

    return(
        <>
            <div className="font-semibold mb-6 pb-3 border-b">Style your signature</div>
            <div className="relative">
                <div className="flex items-center space-x-3">
                    <input className="form-input !w-36" value={state.primary} />
                    <div onClick={() => {handleOptions("primary")}} style={{backgroundColor: state.primary}} className="w-10 h-8 cursor-pointer rounded"></div>
                </div>
                {option === "primary" && <ChromePicker
                    color={ state.primary }
                    onChangeComplete={ (e) => {
                        handleChangeComplete(e, "EDIT", "primary")
                    }}
                />}
            </div>
            <div className="mt-3">
                <label htmlFor="profilePicture" className="form-label">Profile Picture</label>
                <input value={information["profilePicture"]} onChange={(e) => {handleChangeComplete(e, "EDITINFO", "profilePicture")}} type="text" className="form-input" id="profilePicture" name="profilePicture" placeholder="www.yourprofile.com" />
                <span className="text-[12px] text-gray-500 mt-1 leading-none">Link of your profile picture. We do not provide hosting</span>
            </div>
        </>
    )
}

export default Customizations;
