import React from "react";

export const FullName = (information) => {

    return(
        <>
            {information.fullName &&
                <span style={{fontWeight: 600, fontSize: '16px', color: '#555'}}>
                    {information.fullName}
                </span>
            }
            {/*<span>&nbsp;</span>*/}
            {/*{information.lastName && <span>{information.lastName}</span>}*/}
        </>
    )
}
