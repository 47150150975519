import React from 'react';

export const WebsiteUrl = (information) => {
    return(
        <>
            {information.websiteUrl &&
                <a
                    href={information.websiteUrl}
                    color="#000000"
                    className="sc-gipzik iyhjGb"
                    style={{
                        textDecoration: "none",
                        color: "rgb(0, 0, 0)",
                        fontSize: "12px",
                    }}
                >
                    <span>{information.websiteUrl}</span>
                </a>
            }
        </>
    )
}
