import React from 'react';

export const AddressIcon = (information, state) => {
    return(
        <>
            {information.address &&
                <table
                    cellPadding={0}
                    cellSpacing={0}
                    className="sc-gPEVay eQYmiW"
                    style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "medium",
                        fontFamily: "Arial",
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: "bottom" }}>
                                <span
                                    color="#F2547D"
                                    width={11}
                                    className="sc-jlyJG bbyJzT"
                                    style={{
                                        display: "block",
                                        backgroundColor: state.primary,
                                    }}
                                >
                                    <img
                                        src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/address-icon-2x.png"
                                        color="#F2547D"
                                        width={13}
                                        alt={"icon"}
                                        className="sc-iRbamj blSEcj"
                                        style={{
                                            display: "block",
                                            backgroundColor: state.primary,
                                        }}
                                    />
                            </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    )
}
