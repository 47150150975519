import React from 'react';

export const BorderVertical = (information, state) => {
    return(
        <>
            <td width={15}>
                <div style={{ width: "15px" }} />
            </td>
            <td
                color="#F2547D"
                direction="vertical"
                width={1}
                className="sc-jhAzac hmXDXQ"
                style={{
                    width: "1px",
                    borderBottom: "none",
                    borderLeft: `1px solid ${state.primary}`,
                }}
            />
            <td width={15}>
                <div style={{ width: "15px" }} />
            </td>
        </>
    )
}
