import React from 'react';

export const Phone = (information) => {
    return(
        <>
            {information.officePhone && <a
                href={`tel:${information.officePhone}`}
                color="#000000"
                className="sc-gipzik iyhjGb"
                style={{
                    textDecoration: "none",
                    color: "rgb(0, 0, 0)",
                    fontSize: "12px",
                }}>
                    <span> {information.officePhone} </span>
                </a>
            }

            {" "}

            {information.officePhone && information.personalPhone && <>|{" "}</>}

            {information.personalPhone && <a
                href={`tel:${information.personalPhone}`}
                color="#000000"
                className="sc-gipzik iyhjGb"
                style={{
                    textDecoration: "none",
                    color: "rgb(0, 0, 0)",
                    fontSize: "12px",
                }}>
                    <span>{information.personalPhone}</span>
                </a>
            }
        </>
    )
}
