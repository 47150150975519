// import './options.css';
import Media from "./media/Media";
import React, {useState} from "react";
import Wireframes from "./wireframes/Wireframes";
import Informations from "./informations/Informations";
import Customizations from "./customizations/Customizations";
import {tabOptions} from "../helper/constant";

const Options = () => {

    const [localState, setState] = useState({
        currentTab: 'contents'
    })

    const {currentTab} = localState;

    const changeTabs = (tab) => {
        setState(prevState => ({
            ...prevState,
            currentTab: tab
        }))
    }

    const renderTabs = () => {
        switch (currentTab){
            case 'templates':
                return <Wireframes />
            case 'contents':
                return <Informations />
            case 'styles':
                return <Customizations />
            case 'media':
                return <Media />
            default:
                return null;
        }
    }

    const tabOption = tabOptions.map((item,index) => {
        return(
            <li key={index}>
                <div className={`inline-flex items-center justify-center space-x-1 p-2 md:p-4 w-full cursor-pointer border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 group ${currentTab === item.name ? "text-blue-600 border-blue-600" : "border-transparent"}`} onClick={() => changeTabs(item.name)}>
                    <span>{item.svg}</span>
                    <span>{item.label}</span>
                </div>
            </li>
        )
    })

    return(
        <div className="bg-white rounded shadow-sm border">
            <div className="border-b border-gray-200 dark:border-gray-700">
                <ul className="grid grid-cols-3 -mb-px text-sm font-semibold text-center text-gray-500">
                    {tabOption}
                </ul>
            </div>
            <div>
                <div className="p-4">
                    {renderTabs()}
                </div>
            </div>
        </div>
    )
}

export default Options;
