import Swal from 'sweetalert2';
import React, {useState, useEffect} from "react";
import Template3 from "./Templates/Template3";
import Template1 from "./Templates/Template1";
import Template2 from "./Templates/Template2";
import Template4 from "./Templates/Template4";
import GlobalContent from "../context/GlobalContext";
import {
    checkRequiredFields,
    confirmCancelBtn,
    copyContent,
    copyContentHTML
} from "../helper/functions";

const Signature = () => {

    const [localState, setState] = useState({
        createSignature: false
    })

    const {createSignature} = localState;

    useEffect(() => {
        confirmCancelBtn(copySignature);
    })

    const { state, state: {information} } = React.useContext(GlobalContent);

    const copySignature = (html) => {
        let elem = document.getElementById("es-landscape_template");

        html ? copyContentHTML(elem) : copyContent(elem);
    }

    const renderTemplete = () => {
        switch (state.currentTemplate){
            case "1":
                return <Template1 />;
            case "2":
                return <Template2 />;
            case "3":
                return <Template3 />;
            case "4":
                return <Template4 />;
            default:
                return null
        }
    }

    const handleSignature = () => {
        let checkedFields = checkRequiredFields(information);
        if(Object.values(checkedFields).includes(false)){
            Swal.fire({
                title: '<h3><b>These fields are required!!</b></h3>',
                html: `<ul class="text-left">
                    ${!checkedFields.fullName ? "<li class='mb-2'>Full name</li>" : ""}
                    ${!checkedFields.jobTitle ? "<li class='mb-2'>Job title</li>" : ""}
                    ${!checkedFields.companyName ? "<li class='mb-2'>Company name</li>" : ""}
                    ${!checkedFields.personalPhone ? "<li class='mb-2'>Personal phone number</li>" : ""}
                    ${!checkedFields.email ? "<li class='mb-2'>Email</li>" : ""}
                </ul>`,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Got it'
            })
            return null;
        }
        const SendOrCopy = Swal.mixin({
            buttonsStyling: false
        })
        SendOrCopy.fire({
            title: 'Your email signature is ready',
            icon: 'success',
            showConfirmButton: false,
            showCancelButton: false,
            html: `<div class="flex items-center justify-center space-x-3 mt-3">
                        <button id="copy-signature" class="py-2.5 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded">Copy Signatrue</button>
                        <button id="copy-html" class="py-2.5 px-4 border bg-gray-50 hover:bg-gray-100 font-semibold rounded">Copy HTML</button>
                   </div>`,
        })
        setState(prevState => ({
            ...prevState,
            createSignature: !prevState.createSignature
        }))
    }

    return(
        <div className={` ${createSignature && "es-Signature-container-adder"}`}>
            <div className="bg-white shadow rounded">
                <div className="bg-gray-100 rounded-top text-sm rounded-t">
                    <div className="py-2 px-4 border-b">
                        To: <span className="bg-gray-300 rounded-full px-3 py-0.5">ThriveDesk</span>
                    </div>
                    <div className="py-2 px-4">
                        Subject: <span className="font-semibold">My new email signature</span>
                    </div>
                </div>
                <div className="p-6">
                    <div className="space-y-8">
                        <div className="space-y-2">
                            <p>Hi Rachel,</p>
                            <p>Check out this awesome new email signature I created!</p>
                            <p>Love</p>
                        </div>
                        <div className='border-t pt-6'>
                            {renderTemplete()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-gray-500 text-sm text-right mt-1">This is an example. Edit on the left to create your own.</div>
            
            
            <button className="w-full flex items-center justify-center space-x-2 bg-blue-600 hover:bg-blue-500 text-center py-2.5 text-white shadow-sm rounded mt-5 font-semibold" onClick={handleSignature}>
                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#fff" fill="none"><path d="M22 12.634c-4 3.512-4.572-2.013-6.65-1.617-2.35.447-3.85 5.428-2.35 5.428s-.5-5.945-2.5-3.89c-2 2.056-2.64 4.74-4.265 2.748C-1.5 5.813 5-1.15 8.163 3.457 10.165 6.373 6.5 16.977 2 22M9 21h10" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/></svg></span>
                <span>Create Signature</span>
            </button>
        </div>
    )
}

export default Signature;
