import React from 'react';

export const Custominput = (information) => {
    return(
        <>
            {information.customInput &&
                <span style={{fontSize: "13px"}}>
                    {information.customInput}
                </span>
            }
        </>
    )
}
