import Swal from "sweetalert2";

export const copyContent = (element) => {
    let selection = window.getSelection();
    let range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeRange(range);
}

export const copyContentHTML = (element) => {
    const textArea = document.createElement("textarea");
    // textArea.style.visibility = "hidden";
    // textArea.style.position = "absolute";
    document.querySelector("body").appendChild(textArea);
    textArea.value = element.innerHTML;
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
}


export const templeteBottomDesignRender = (state) => {
    return !!(state.linkedin || state.facebook || state.twitter || state.instagram || state.organizationPicture);
}

export const templeteMiddleDesignRender = (state) => {
    return !!((state.officePhone || state.personalPhone ||
        state.websiteUrl || state.email || state.address) && (state.fullName || state.lastName || state.jobTitle ||
        state.department || state.companyName || state.linkedin ||
        state.facebook || state.twitter || state.instagram || state.profilePicture || state.organizationPicture));
}

export const copySignatureButtonCondition = (state) => {
    return !!(state.fullName || state.lastName || state.jobTitle ||
        state.department || state.companyName ||
        state.officePhone || state.personalPhone ||
        state.websiteUrl || state.email || state.address_0 ||
        state.address_1 || state.address_2 || state.address_3 || state.linkedin ||
        state.facebook || state.twitter || state.instagram || state.profilePicture || state.organizationPicture);
}

export const checkRequiredFields =  (state) => {
    return ({
        fullName: !!state.fullName,
        jobTitle: !!state.jobTitle,
        companyName: !!state.companyName,
        personalPhone: !!state.personalPhone,
        email: !!state.email
    })
}

export const confirmCancelBtn = (copySignature) => {
    const copySig = document.getElementById("copy-signature");
    const copyHtml = document.getElementById("copy-html");

    copySig && copySig.addEventListener("click", () => {
        copySignature()
        Swal.fire(
            'Copied!',
            'Your have copied the signature.',
            'success'
        )
    })

    copyHtml && copyHtml.addEventListener("click", () => {
        copySignature(true)
        Swal.fire(
            'Copied!',
            'Your have copied the HTML.',
            'success'
        )
    })
}
