import React from 'react';
import Fields from './Fields';
import GlobalContent from "../../context/GlobalContext";
import {copySignatureButtonCondition} from "../../helper/functions";
import {replicaState} from "../../helper/constant";

const CommonItems = ({item}) => {

    let { state, state: {information} } = React.useContext(GlobalContent);

    information = copySignatureButtonCondition(information) ? information : replicaState;
    
    return(
        <>
            {Fields[item](information, state)}
        </>
    )
}

export default CommonItems;
