import React from 'react';

export const Organization = (information) => {
    return(
        <>
            {/*{information.department && <span>{information.department} </span>}*/}
            {/*{information.department && information.companyName && <span>&nbsp;|&nbsp;</span>}*/}
            {information.companyName &&
                    <span style={{fontSize: "13px"}}>
                        {information.companyName}
                    </span>
            }
        </>
    )
}
