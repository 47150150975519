import React from 'react';
import CommonItems from "../index";

export const TemplateCommonMedia = () => {
    return(
        <td style={{ verticalAlign: "top" }}>
            <table
                cellPadding={0}
                cellSpacing={0}
                className="sc-gPEVay eQYmiW"
                style={{
                    verticalAlign: "-webkit-baseline-middle",
                    fontSize: "medium",
                    fontFamily: "Arial",
                }}
            >
                <tbody>
                <tr>
                    <td
                        className="sc-TOsTZ kjYrri"
                        style={{ textAlign: "center" }}
                    >
                        <CommonItems item={"ProfilePicture"}/>
                    </td>
                </tr>
                <tr>
                    <td height={15} />
                </tr>
                {/*<tr>*/}
                {/*    <td*/}
                {/*        className="sc-TOsTZ kjYrri"*/}
                {/*        style={{ textAlign: "center" }}*/}
                {/*    >*/}
                {/*        <CommonItems item={"OrganizationPicture"} />*/}
                {/*    </td>*/}
                {/*</tr>*/}
                <tr>
                    <td height={15} />
                </tr>
                <tr>
                    <td style={{ textAlign: "center" }}>
                        <table
                            cellPadding={0}
                            cellSpacing={0}
                            className="sc-gPEVay eQYmiW"
                            style={{
                                verticalAlign: "-webkit-baseline-middle",
                                fontSize: "medium",
                                fontFamily: "Arial",
                                display: "inline-block",
                            }}
                        >
                            <tbody>
                            <tr style={{ textAlign: "center" }}>
                                <td>
                                    <CommonItems item={"Facebook"} />
                                </td>
                                <td width={5}>
                                    <div />
                                </td>
                                <td>
                                    <CommonItems item={"Twitter"} />
                                </td>
                                <td width={5}>
                                    <div />
                                </td>
                                <td>
                                    <CommonItems item={"Instagram"} />
                                </td>
                                <td width={5}>
                                    <div />
                                </td>
                                <td>
                                    <CommonItems item={"Linkedin"} />
                                </td>
                                <td width={5}>
                                    <div />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    )
}
