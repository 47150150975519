import React from "react";
import Options from "../Options";
import Signature from "../Signature";

const MainContainer = () => {
    return(
            <>
                <div className="max-w-6xl mx-auto py-8 md:py-16 px-3">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="md:col-span-1">
                            <Options />
                        </div>
                        <div className="md:col-span-2">
                            <Signature />
                        </div>
                    </div>
                </div>
            </>
    )
}

export default MainContainer;
