import React from "react";
import {signatureDetails} from "../../helper/constant";
import GlobalContent from "../../context/GlobalContext";

const Informations = () => {

    const {dispatch, state: {information}} = React.useContext(GlobalContent);

    const handleChange = (e) => {
        const {name, value} = e.target;
        dispatch({
            type: "EDITINFO",
            payload: {
                name: name,
                value: value
            }
        })
    }

    const fields = {
        address(item) {
            return <textarea
                        id={item.name}
                        type={item.type}
                        name={item.name}
                        onChange={handleChange}
                        className="form-input"
                        value={information[item.name]}
                        placeholder={item.placeholder}/>
        },
        others(item) {
            return  <input
                        id={item.name}
                        type={item.type}
                        name={item.name}
                        className="form-input"
                        onChange={handleChange}
                        value={information[item.name]}
                        placeholder={item.placeholder}/>
        },
        phone(item) {
            return <>

                <p className="form-label">Phone Number</p>
                <div className="grid grid-cols-2 gap-2">
                {item.items.map((itm, ind) => (
                    <input
                        id={itm.name}
                        type={itm.type}
                        name={itm.name}
                        onChange={handleChange}
                        className="form-input"
                        value={information[itm.name]}
                        placeholder={itm.placeholder}/>
                ))}
                </div>

            </>
        },
    }

    const signatureField = signatureDetails.length > 0 && signatureDetails.map((item, index) => {
        return(
            <div key={index}>

                {item.phone ? fields.phone(item) : <>

                    <label className="form-label" htmlFor={item.name}>{item.label}</label>

                    {item.name === 'address' ? fields.address(item) : fields.others(item)}

                </>}
            </div>
        )
    })

    return(
        <div className="space-y-3">
            {signatureField}
            <div>
                <label className="form-label"  htmlFor="customInput">Custom Input</label>
                <input value={information["customInput"]} onChange={handleChange} type="text" className="form-input" id="customInput" name="customInput" placeholder="Bonus Content" />
            </div>
        </div>
    )
}

export default Informations;
